<template>
  <section class="pb-1" v-if="caseData">
    <h5 class="text-nowrap font-weight-bolder" v-if="caseData">
      {{ getUniqueId(caseData) }}
      <b-badge
        variant="light-primary"
        class="mx-1"
        v-if="caseData.case_status.toLowerCase() === 'pending'"
      >
        <span>{{ caseData.case_status }}</span>
      </b-badge>
      <b-badge variant="light-danger" class="mx-1" v-else>
        <span>{{ caseData.case_status }}</span>
      </b-badge>
    </h5>
    <div
      v-if="
        Object.keys($store.state.upCaveatStore.currentFIR).includes(
          'court_name'
        )
      "
    >
      <p class="text-secondary font-italic">
        {{ $store.state.upCaveatStore.currentFIR.court_name }}
      </p>
    </div>
    <h4 class="text-primary">
      {{ caseData.title }}
    </h4>
  </section>
</template>

<script>
import {
  BBadge,
  BRow,
  BButton,
  BCol,
  BFormGroup,
  BFormTextarea
} from 'bootstrap-vue'

export default {
  components: { BBadge, BRow, BButton, BCol, BFormGroup, BFormTextarea },
  props: {
    data: {
      default: null
    }
  },
  data() {
    return {
      caseData: this.data
    }
  }
}
</script>
