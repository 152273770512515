<template>
  <div v-if="detail !== null">
    <section v-if="detail.length">
      <h3 v-if="title" class="pb-1">{{ title }}</h3>
      <!-- <b-row>
        <b-col class="col-12">
          <app-collapse :type="'border'">
            <app-collapse-item title="View IA Details" class="text-info">
              <b-table
                stacked
                responsive="sm"
                :items="detail"
              /> </app-collapse-item
          ></app-collapse>
        </b-col>
      </b-row> -->
      <b-card no-body class="py-1">
        <div v-for="(item, index) in detail" :key="index">
          <hr style="margin: 5px 0" v-if="index !== 0" />
          <div v-for="(value, key) in item">
            <b-col class="py-50">
              <b-row class="px-1">
                <b-col class="col-5">
                  <h6 class="m-0">
                    {{
                      key
                        .split('_')
                        .join(' ')
                        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                          letter.toUpperCase()
                        )
                    }} :
                  </h6>
                </b-col>
                <b-col class="col-7">
                  <li class="m-0">
                    {{ value }}
                  </li>
                </b-col>
              </b-row>
            </b-col>
          </div>
        </div>
      </b-card>
    </section>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BTable } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    AppCollapse,
    AppCollapseItem
  },
  props: {
    data: {
      default: null
    },
    title: {
      default: 'Details'
    }
  },
  data() {
    return {
      detail: this.data,
      heading: this.title
    }
  }
}
</script>
