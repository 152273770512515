import { render, staticRenderFns } from "./PetitionerRespondents.vue?vue&type=template&id=97fa5858"
import script from "./PetitionerRespondents.vue?vue&type=script&lang=js"
export * from "./PetitionerRespondents.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports