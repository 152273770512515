var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('h3',{staticClass:"pb-1"},[_vm._v("Petitioner/Respondent and their Advocate(s)")]),_c('b-row',[_c('b-col',{staticClass:"col-12 col-md-6 pb-1"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-row',{staticClass:"justify-content-between px-1"},[_c('b-card-title',{staticClass:"pl-1 pt-1"},[_vm._v("Petitioners")]),_c('b-row',{staticClass:"justify-content-end pr-1 pt-1"},[_c('div',{staticClass:"pr-50"},[_c('b-button',{staticClass:"btn btn-sm text-primary",attrs:{"variant":"light"},on:{"click":_vm.togglePetitionersEditing}},[_vm._v(_vm._s(_vm.isPetitionersEditing ? 'Cancel' : 'Edit'))])],1),(
                Object.keys(_vm.data).includes('petitioner_advocates') &&
                _vm.data.petitioner_advocates.length > 1 &&
                !_vm.isPetitionersEditing
              )?_c('div',{staticClass:"pr-50"},[_c('b-button',{staticClass:"btn btn-sm text-primary",attrs:{"variant":"light"},on:{"click":_vm.togglePetitionersLimit}},[_vm._v(_vm._s(_vm.isPetitionersExpanded ? 'Less' : 'More'))])],1):_vm._e()])],1),(_vm.isPetitionersEditing)?_c('div',{staticClass:"p-50"},[_c('b-form-group',{attrs:{"label-for":"user-petitioners"}},[_c('b-form-tags',{attrs:{"placeholder":"Add Petitioners","input-id":"user-petitioners"},model:{value:(_vm.petitioner_advocates),callback:function ($$v) {_vm.petitioner_advocates=$$v},expression:"petitioner_advocates"}}),_c('b-form-invalid-feedback',{staticClass:"text-info",attrs:{"state":false}},[_vm._v(" Type and press enter to push new petitioners and please add 'Advocate - ' in prefix for advocates. ")])],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2 btn btn-sm",attrs:{"variant":"primary"},on:{"click":_vm.updatePetitioners}},[_vm._v(" Update ")])],1)],1):_vm._e(),_vm._l((_vm.data.petitioner_advocates.slice(
            0,
            _vm.sliceLimitPetitioners
          )),function(text){return (!_vm.isPetitionersEditing && Object.keys(_vm.data).includes('petitioner_advocates'))?_c('b-list-group-item',{key:text,attrs:{"data":text}},[_vm._v(_vm._s(text))]):_vm._e()})],2)],1),_c('b-col',{staticClass:"col-12 col-md-6 pb-1"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-row',{staticClass:"justify-content-between px-1"},[_c('b-card-title',{staticClass:"pl-1 pt-1"},[_vm._v("Respondents")]),_c('b-row',{staticClass:"justify-content-end pr-1 pt-1"},[_c('div',{staticClass:"pr-50"},[_c('b-button',{staticClass:"btn btn-sm text-primary",attrs:{"variant":"light"},on:{"click":_vm.toggleRespondentsEditing}},[_vm._v(_vm._s(_vm.isRespondentsEditing ? 'Cancel' : 'Edit'))])],1),(
                Object.keys(_vm.data).includes('respondent_advocates') && _vm.data.respondent_advocates.length > 1 && !_vm.isRespondentsEditing
              )?_c('div',{staticClass:"pr-50"},[_c('b-button',{staticClass:"btn btn-sm text-primary",attrs:{"variant":"light"},on:{"click":_vm.toggleRespondentsLimit}},[_vm._v(_vm._s(_vm.isRespondentsExpanded ? 'Less' : 'More'))])],1):_vm._e()])],1),(_vm.isRespondentsEditing)?_c('div',{staticClass:"p-50"},[_c('b-form-group',{attrs:{"label-for":"user-respondents"}},[_c('b-form-tags',{attrs:{"placeholder":"Add Respondents","input-id":"user-respondents"},model:{value:(_vm.respondent_advocates),callback:function ($$v) {_vm.respondent_advocates=$$v},expression:"respondent_advocates"}}),_c('b-form-invalid-feedback',{staticClass:"text-info",attrs:{"state":false}},[_vm._v(" Type and press enter to push new respondents and please add 'Advocate - ' in prefix for advocates. ")])],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2 btn btn-sm",attrs:{"variant":"primary"},on:{"click":_vm.updateRespondents}},[_vm._v(" Update ")])],1)],1):_vm._e(),_vm._l((_vm.data.respondent_advocates.slice(
            0,
            _vm.sliceLimitRespondents
          )),function(text){return (!_vm.isRespondentsEditing && Object.keys(_vm.data).includes('respondent_advocates'))?_c('b-list-group-item',{key:text,attrs:{"data":text}},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }