<template>
  <b-card no-body v-if="items.length">
    <b-col class="p-0">
        <h4 class="p-0 my-1 mx-1">{{ heading }}</h4>
        <b-table responsive="sm" :items="items" />
    </b-col>
  </b-card>
</template>

<script>
import { BTable, BCard, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BCol
  },
  props: {
    title: {
        default: ""
    },
    data: {
      default: null
    }
  },
  data() {
    return {
      items: this.data,
      heading: this.title
    }
  }
}
</script>
