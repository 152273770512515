<template>
  <div v-if="data.linked_matters !== null">
    <section v-if="Object.keys(data.linked_matters).length">
      <h3>Linked Matters</h3>
      <b-card>
        <!-- First Hearing Date -->
        <b-row class="px-1">
          <b-col class="col-12"
            ><p class="m-0">{{ data.linked_matters.join(', ') }}</p></b-col
          >
        </b-row>
      </b-card>
    </section>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'

export default {
  components: { BCard, BRow, BCol },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter
    }
  }
}
</script>
