<template>
  <section v-if="data.case_details !== null">
    <h3 class="pb-1">Case Status</h3>
    <b-card no-body class="py-75">
      <!-- First Hearing Date -->
      <!-- <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">First Hearing</h6>
        </b-col>
        <b-col class="col-7">
          <p
            class="m-0"
            v-if="Object.keys(data.case_details).includes('first_hearing_date')"
          >
            {{ data.case_details.first_hearing_date }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row> -->
      <!-- <hr style="margin: 5px 0" /> -->
      <!-- Next Hearing Date -->
      <b-col>
        <b-row>
          <b-col class="col-5">
            <h6 class="m-0">Last Hearing</h6>
          </b-col>
          <b-col class="col-7">
            <div v-if="Object.keys(data).includes('listing_history')">
              <div v-if="data.listing_history.length">
                <p
                  class="m-0 text-primary"
                  v-if="
                    Object.keys(data.listing_history[0]).includes(
                      'listing_date'
                    )
                  "
                >
                  {{ data.listing_history[0].listing_date }}
                </p>
                <p class="m-0" v-else>--</p>
              </div>
              <p class="m-0" v-else>--</p>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <!-- Next Hearing Date -->
      <b-col v-if="Object.keys(data).includes('hearing_dates')">
        <hr style="margin: 5px 0" />
        <b-row>
          <b-col class="col-5">
            <h6 class="m-0">Next Hearing</h6>
          </b-col>
          <b-col class="col-7">
            <p
              class="m-0 text-success font-weight-bold"
              v-if="data.hearing_dates !== null"
            >
              {{ getNextDate({ dates: data.hearing_dates }) }}
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <hr style="margin: 5px 0" />
        <!-- Next Hearing Date -->
        <b-row>
          <b-col class="col-5">
            <h6 class="m-0">User Next Date</h6>
          </b-col>
          <b-col class="col-7">
            <!-- <div v-if="Object.keys(data).includes('hearing_date')">
              <p class="m-0 text-info font-weight-bold">
                {{ data.hearing_date !== null ? data.hearing_date : '--' }}
              </p>
            </div> -->
            <p class="m-0">
              <b-row>
                <b-input-group size="sm" class="mr-1">
                  <b-form-datepicker
                    id="start-datepicker"
                    size="sm"
                    v-model="userDate"
                    :min="minDate"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      :disabled="!userDate"
                      @click="updateUserDate"
                    >
                      Update
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-row>
            </p>
          </b-col>
        </b-row>
      </b-col>
      <div v-for="(item, index) in Object.keys(data.case_details)" :key="index">
        <b-col>
          <hr style="margin: 5px 0" />
          <b-row>
            <b-col class="col-5">
              <h6 class="m-0">
                {{
                  item
                    .split('_')
                    .join(' ')
                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                      letter.toUpperCase()
                    )
                }}
              </h6>
            </b-col>
            <b-col class="col-7">
              <p class="m-0">
                {{ data.case_details[item] }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </div>

      <!-- Bench Type -->
      <!-- <status-list-item
        :details="data.case_details"
        :title="'Bench Type'"
        :itemKey="'bench_type'"
      /> -->

      <!-- Coram -->
      <!-- <status-list-item
        :details="data.case_details"
        :title="'Coram'"
        :itemKey="'coram'"
      /> -->

      <!-- State -->
      <!-- <status-list-item
        :details="data.case_details"
        :title="'State'"
        :itemKey="'state'"
      /> -->

      <!-- District -->
      <!-- <status-list-item
        :details="data.case_details"
        :title="'District'"
        :itemKey="'district'"
      /> -->
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import StatusListItem from './StatusListItem.vue'
import moment from 'moment'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    StatusListItem
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter,
      userDate: Object.keys(this.matter).includes('hearing_date')
        ? this.matter.hearing_date
        : null
    }
  },
  computed: {
    minDate() {
      const today = new Date().toISOString().split('T')[0]
      return today
    }
  },
  methods: {
    getNextDate({ dates }) {
      var date = '--'
      var dd = []
      const filteredDates = dates.filter(
        (date) => moment(date) >= moment().startOf('day')
      )
      for (let i = 0; i < filteredDates.length; i++) {
        dd.push(moment(filteredDates[i]).format('Do, MMMM YYYY'))
      }
      date = dd.join(' || ')
      return date
    },
    updateUserDate() {
      event.preventDefault()
      this.$store
        .dispatch('matterStore/updateUserDateData', {
          ctx: this,
          matter_id: this.data._id,
          payload: {
            hearing_date: this.userDate
          }
        })
        .then((response) => {
          console.log(response)
          if (response.success) {
            this.userDate = response.data.hearing_date
          }
        })
    }
  },
  setup() {
    return {
      formatDate
    }
  }
}
</script>
