<template>
  <section>
    <h3 class="pb-1">Petitioner/Respondent and their Advocate(s)</h3>
    <b-row>
      <b-col class="col-12 col-md-6 pb-1">
        <b-card no-body>
          <b-row class="justify-content-between px-1">
            <b-card-title class="pl-1 pt-1">Petitioners</b-card-title>
            <b-row class="justify-content-end pr-1 pt-1">
              <div class="pr-50">
                <b-button
                  @click="togglePetitionersEditing"
                  class="btn btn-sm text-primary"
                  variant="light"
                  >{{ isPetitionersEditing ? 'Cancel' : 'Edit' }}</b-button
                >
              </div>
              <div
                class="pr-50"
                v-if="
                  Object.keys(data).includes('petitioner_advocates') &&
                  data.petitioner_advocates.length > 1 &&
                  !isPetitionersEditing
                "
              >
                <b-button
                  @click="togglePetitionersLimit"
                  class="btn btn-sm text-primary"
                  variant="light"
                  >{{ isPetitionersExpanded ? 'Less' : 'More' }}</b-button
                >
              </div>
            </b-row>
          </b-row>
          <div v-if="isPetitionersEditing" class="p-50">
            <b-form-group label-for="user-petitioners">
              <b-form-tags
                v-model="petitioner_advocates"
                placeholder="Add Petitioners"
                input-id="user-petitioners"
              />
              <b-form-invalid-feedback :state="false" class="text-info">
                Type and press enter to push new petitioners and please add
                'Advocate - ' in prefix for advocates.
              </b-form-invalid-feedback>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2 btn btn-sm"
                @click="updatePetitioners"
              >
                Update
              </b-button>
            </div>
          </div>
          <b-list-group-item
            v-if="!isPetitionersEditing && Object.keys(data).includes('petitioner_advocates')"
            v-for="text in data.petitioner_advocates.slice(
              0,
              sliceLimitPetitioners
            )"
            v-bind:data="text"
            v-bind:key="text"
            >{{ text }}</b-list-group-item
          >
        </b-card>
      </b-col>
      <b-col class="col-12 col-md-6 pb-1">
        <b-card no-body>
          <b-row class="justify-content-between px-1">
            <b-card-title class="pl-1 pt-1">Respondents</b-card-title>
            <b-row class="justify-content-end pr-1 pt-1">
              <div class="pr-50">
                <b-button
                  @click="toggleRespondentsEditing"
                  class="btn btn-sm text-primary"
                  variant="light"
                  >{{ isRespondentsEditing ? 'Cancel' : 'Edit' }}</b-button
                >
              </div>
              <div
                class="pr-50"
                v-if="
                  Object.keys(data).includes('respondent_advocates') && data.respondent_advocates.length > 1 && !isRespondentsEditing
                "
              >
                <b-button
                  @click="toggleRespondentsLimit"
                  class="btn btn-sm text-primary"
                  variant="light"
                  >{{ isRespondentsExpanded ? 'Less' : 'More' }}</b-button
                >
              </div>
            </b-row>
          </b-row>
          <div v-if="isRespondentsEditing" class="p-50">
            <b-form-group label-for="user-respondents">
              <b-form-tags
                v-model="respondent_advocates"
                placeholder="Add Respondents"
                input-id="user-respondents"
              />
              <b-form-invalid-feedback :state="false" class="text-info">
                Type and press enter to push new respondents and please add
                'Advocate - ' in prefix for advocates.
              </b-form-invalid-feedback>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2 btn btn-sm"
                @click="updateRespondents"
              >
                Update
              </b-button>
            </div>
          </div>
          <b-list-group-item
            v-if="!isRespondentsEditing && Object.keys(data).includes('respondent_advocates')"
            v-for="text in data.respondent_advocates.slice(
              0,
              sliceLimitRespondents
            )"
            v-bind:data="text"
            v-bind:key="text"
          >
            {{ text }}
          </b-list-group-item>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInvalidFeedback,
  BFormTags,
  BCardTitle
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardTitle,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTags,
    AppCollapse,
    AppCollapseItem,
    BListGroup,
    BListGroupItem
  },
  directives: {
    Ripple
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter,
      respondent_advocates: Object.keys(this.matter).includes('respondent_advocates') ? this.matter.respondent_advocates : [],
      petitioner_advocates: Object.keys(this.matter).includes('petitioner_advocates') ? this.matter.petitioner_advocates : [],
      sliceLimitPetitioners: 1,
      isPetitionersExpanded: false,
      isPetitionersEditing: false,
      sliceLimitRespondents: 1,
      isRespondentsExpanded: false,
      isRespondentsEditing: false
    }
  },
  methods: {
    togglePetitionersEditing() {
      if (this.isPetitionersEditing) {
        this.isPetitionersEditing = false
      } else {
        this.isPetitionersEditing = true
      }
    },
    toggleRespondentsEditing() {
      if (this.isRespondentsEditing) {
        this.isRespondentsEditing = false
      } else {
        this.isRespondentsEditing = true
      }
    },
    togglePetitionersLimit() {
      if (this.isPetitionersExpanded) {
        this.sliceLimitPetitioners = 1
        this.isPetitionersExpanded = false
      } else {
        this.sliceLimitPetitioners = 100
        this.isPetitionersExpanded = true
      }
    },
    toggleRespondentsLimit() {
      if (this.isRespondentsExpanded) {
        this.sliceLimitRespondents = 1
        this.isRespondentsExpanded = false
      } else {
        this.sliceLimitRespondents = 100
        this.isRespondentsExpanded = true
      }
    },
    updateRespondents() {
      let advocateList = []
      let filteredList = []

      for (let item of this.respondent_advocates) {
        if (item.startsWith('Advocate')) {
          advocateList.push(item)
        } else {
          filteredList.push(item)
        }
      }

      let finalList = filteredList.concat(advocateList)
      event.preventDefault()
      this.$store
        .dispatch('matterStore/updateTitleData', {
          ctx: this,
          matter_id: this.data._id,
          payload: {
            respondent_advocates: finalList
          }
        })
        .then((response) => {
          console.log(response)
          if (response.success) {
            this.data.respondent_advocates = finalList
            this.isRespondentsEditing = false
          }
        })
    },
    updatePetitioners() {
      let advocateList = []
      let filteredList = []

      for (let item of this.petitioner_advocates) {
        if (item.startsWith('Advocate')) {
          advocateList.push(item)
        } else {
          filteredList.push(item)
        }
      }

      let finalList = filteredList.concat(advocateList)
      event.preventDefault()
      this.$store
        .dispatch('matterStore/updateTitleData', {
          ctx: this,
          matter_id: this.data._id,
          payload: {
            petitioner_advocates: finalList
          }
        })
        .then((response) => {
          console.log(response)
          if (response.success) {
            this.data.petitioner_advocates = finalList
            this.isPetitionersEditing = false
          }
        })
    }
  }
}
</script>
