var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"matter-preview-wrapper"},[(
      _vm.$store.state.upCaveatStore.currentFIRCase &&
      !_vm.$store.state.upCaveatStore.isFIRCaseLoading
    )?_c('b-row',{staticClass:"matter-preview"},[_c('b-col',{attrs:{"cols":"12","xl":"9","md":"8"}},[_c('b-card',[_c('CaseDetails',{attrs:{"data":_vm.$store.state.upCaveatStore.currentFIRCase}})],1)],1),_c('b-col',{staticClass:"matter-actions",attrs:{"cols":"12","md":"4","xl":"3"}},[(
          !Object.keys(_vm.$store.state.upCaveatStore.currentFIRCase).includes(
            'case_exists'
          )
        )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.addToMatter()}}},[_vm._v(" Add to Matter List ")]):_vm._e(),(!_vm.$store.state.upCaveatStore.currentFIRCase.case_exists)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.addToMatter()}}},[_vm._v(" Add to Matter List ")]):_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"light","block":""}},[_vm._v(" Already Added to Matter ")])],1)],1):_vm._e(),(
      !_vm.$store.state.upCaveatStore.currentFIRCase &&
      _vm.$store.state.upCaveatStore.isFIRCaseLoading
    )?_c('b-card',{staticClass:"text-center mt-2"},[_c('div',{staticClass:"d-flex justify-content-center my-2"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1)]):_vm._e(),(
      !_vm.$store.state.upCaveatStore.currentFIRCase &&
      !_vm.$store.state.upCaveatStore.isFIRCaseLoading
    )?_c('b-card',{staticClass:"text-center mt-2"},[_vm._v(" Please Try Again! ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }