<template>
    <b-col v-if="Object.keys(details).includes(itemKey)">
        <hr style="margin: 5px 0" />
        <b-row class="px-1">
          <b-col class="col-5">
            <h6 class="m-0">{{title}}</h6>
          </b-col>
          <b-col class="col-7">
            <p class="m-0">
              {{ details[itemKey] }}
            </p>
          </b-col>
        </b-row>
      </b-col>
</template>

<script>
import {BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol
  },
  props: {
    details: {
      default: null
    },
    title: {
        default: ''
    },
    itemKey: {
        default: null
    }
  },
  data() {
    return {
      data: this.matter
    }
  }
}
</script>
