<template>
  <div v-if="data.listing_history !== null">
    <section v-if="data.listing_history.length">
      <h3 class="pb-1">Listing History</h3>
      <b-card no-body>
        <!-- toggle button -->
        <b-table
          stacked
          responsive="sm"
          :items="data.listing_history.slice(0, sliceLimit)"
        />
        <hr class="m-0" />
        <div class="text-center" v-if="data.listing_history.length > 1">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="light"
            class="btn btn-sm text-primary my-1"
            v-on:click="toggleSliceLimit"
          >
            {{ isExpanded ? 'View Less' : 'View More' }}
          </b-button>
        </div>
      </b-card>
    </section>
  </div>
</template>

<script>
import {
  BCollapse,
  BButton,
  VBToggle,
  BCard,
  BCardText,
  BTable
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BTable,
    BCollapse
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter,
      sliceLimit: 1,
      isExpanded: false
    }
  },
  methods: {
    toggleSliceLimit() {
      if (this.isExpanded) {
        this.sliceLimit = 1
        this.isExpanded = false
      } else {
        this.sliceLimit = 100
        this.isExpanded = true
      }
    }
  }
}
</script>
