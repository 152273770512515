<template>
  <div v-if="data.crime_dic !== null">
    <section v-if="Object.keys(data.crime_dic).length">
      <h3 class="pb-1">Crime Details</h3>
      <b-card no-body class="py-1">
        <!-- District -->
        <b-row class="px-1">
          <b-col class="col-5">
            <h6>District</h6>
          </b-col>
          <b-col class="col-7">
            <p
              class="m-0"
              v-if="Object.keys(data.crime_dic).includes('district')"
            >
              {{ data.crime_dic.district }}
            </p>
            <p class="m-0" v-else>--</p>
          </b-col>
        </b-row>
        <hr />
        <!-- Police Station -->
        <b-row class="px-1">
          <b-col class="col-5">
            <h6>Police Station</h6>
          </b-col>
          <b-col class="col-7">
            <p
              class="m-0"
              v-if="Object.keys(data.crime_dic).includes('police_station')"
            >
              {{ data.crime_dic.police_station }}
            </p>
            <p class="m-0" v-else>--</p>
          </b-col>
        </b-row>

        <hr />
        <!-- Crime No. -->
        <b-row class="px-1">
          <b-col class="col-5">
            <h6>Crime No.</h6>
          </b-col>
          <b-col class="col-7">
            <p
              class="m-0"
              v-if="Object.keys(data.crime_dic).includes('crime_no')"
            >
              {{ data.crime_dic.crime_no }}
            </p>
            <p class="m-0" v-else>--</p>
          </b-col>
        </b-row>
        <hr />
        <!-- Year -->
        <b-row class="px-1">
          <b-col class="col-5">
            <h6>Year</h6>
          </b-col>
          <b-col class="col-7">
            <p class="m-0" v-if="Object.keys(data.crime_dic).includes('year')">
              {{ data.crime_dic.year }}
            </p>
            <p class="m-0" v-else>--</p>
          </b-col>
        </b-row>
      </b-card>
    </section>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter
    }
  }
}
</script>
