<template>
  <div v-if="data.lower_court != null">
    <section v-if="Object.keys(data.lower_court).length">
      <h3 class="pb-1">Lower Court</h3>
      <b-card no-body class="py-1">
        <!-- Case Number -->
        <div
          v-for="(item, index) in Object.keys(data.lower_court[0])"
          :key="index"
        >
          <b-col>
            <hr style="margin: 5px 0" v-if="index !== 0" />
            <b-row>
              <b-col class="col-5">
                <h6 class="m-0">
                  {{
                    item
                      .split('_')
                      .join(' ')
                      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                        letter.toUpperCase()
                      )
                  }}
                </h6>
              </b-col>
              <b-col class="col-7">
                <p class="m-0">
                  {{ data.lower_court[0][item] }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </div>
      </b-card>
    </section>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter
    }
  }
}
</script>
